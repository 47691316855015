<template>
    <v-app>    
        <v-main>
            <v-container class="fill-height container-background" fluid></v-container>
            <v-container 
                class="fill-height justify-center" 
                fluid

            >
                <v-row 
                    align="center" 
                    justify="center" 
                    style="z-index:10"
                    class="my-auto"
                >
                    <v-col cols="12">
                        <v-expand-transition>
                            <v-card 
                                width="400"
                                v-show="expand"
                                class="elevation-12 mx-auto pa-4"
                            >                            
                                <v-row
                                    justify="center"
                                >
                                    <v-img
                                        src="../../assets/logo.png"
                                        max-width="150"
                                        contain
                                        class="d-flex"
                                    ></v-img>
                                </v-row>
                                <v-row no-gutters> 
                                    <v-col cols="12">
                                        <div 
                                            class="pa-4"
                                            v-if="getloginErrors"
                                        >
                                            <v-alert                                                    
                                                type="error"
                                                outlined                                                    
                                                
                                                class="red--text"
                                            >
                                                {{ getloginErrors }}
                                            </v-alert>    
                                        </div>                                       
                                        
                                        <div class="text-center font-weight-light primary--text mt-6">Welcome to</div>
                                        <div 
                                            class="text-h4 text-sm-h5 font-weight-black text-center primary--text"
                                            v-html="schoolName"
                                        ></div>        
                                        <v-card-text>
                                            <div class="primary--text text-h5 text-center">Student Registration Portal</div>                                            
                                        </v-card-text>

                                        <v-form 
                                            class="pa-5"
                                            @submit.prevent
                                            ref="form"
                                        >
                                            <v-text-field
                                                ref="birth_certificate_pin"
                                                label="Birth Certificate Pin / SEA Number"                                                
                                                type="text"
                                                color="primary"
                                                v-model="formData.birth_certificate_pin"
                                                clearable
                                                autofocus
                                                v-on:keyup="keyPressId"  
                                                :rules="[() => !!formData.birth_certificate_pin || 'This field is required']"                                                
                                            >
                                                <v-icon
                                                    slot="prepend"
                                                    color="primary"
                                                >
                                                    mdi-account
                                                </v-icon>
                                            </v-text-field> 

                                            <!-- <v-text-field
                                                ref="password"
                                                label="Password"
                                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                @click:append="show = !show"   
                                                :type="show ? 'text' : 'password'"                                                
                                                hint="Birth Certificate Pin / SEA Number"
                                                persistent-hint                                                                                                   
                                                name="password"
                                                v-model="formData.password"
                                                v-on:keyup="keyPressPassword"
                                                clearable                                                        
                                            >
                                                <v-icon
                                                    slot="prepend"
                                                    color="primary"
                                                >
                                                    mdi-key
                                                </v-icon>
                                            </v-text-field> -->

                                            <v-menu
                                                ref="menu"
                                                v-model="menu"
                                                :close-on-content-click="false"
                                                :return-value.sync="formData.date_of_birth"
                                                transistion="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        ref="date_of_birth"
                                                        v-model="formData.date_of_birth"
                                                        label="Date of Birth"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        :rules="[() => !!formData.date_of_birth || 'This field is required']"
                                                    ></v-text-field>
                                                </template>

                                                <v-date-picker
                                                    v-model="formData.date_of_birth"
                                                    scrollable
                                                    no-title
                                                >
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="menu = false"
                                                    >
                                                        Cancel  
                                                    </v-btn>
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="$refs.menu.save(formData.date_of_birth)"
                                                    >
                                                        OK
                                                    </v-btn>
                                                </v-date-picker>
                                            </v-menu>
                                    
                                            <div 
                                                class="text-right mt-6 pt-4"
                                            >                                                
                                                <v-btn 
                                                    color="primary" 
                                                    @click="submit" 
                                                    dark
                                                    block
                                                >LOGIN</v-btn>
                                            </div>
                                             
                                        </v-form>        
                                    </v-col>
                                </v-row>

                                <!-- <v-card-actions>
                                    <div>
                                        <v-btn
                                            v-if="studentIdQuery"
                                            text
                                            color="indigo darken-4"
                                            class="text-caption mt-5"
                                            @click="openDialog(true)"
                                        >
                                            Click here to get your student ID
                                        </v-btn>
                                    </div>    
                                </v-card-actions> -->
                                  
                                                                 
                               
                            </v-card>
                        </v-expand-transition>
                    </v-col>
                </v-row>
                <v-overlay
                    :value="getOverlay"
                    opacity="0.8"
                    z-index="10"                    
                >
                    
                    <v-progress-circular
                        indeterminate
                        size="64"
                    ></v-progress-circular>                   
                </v-overlay>
                <query-id/>
            </v-container>            
        </v-main>        
    </v-app>
</template>

<script>
import QueryId from '../QueryId'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'Login',
    components: {
        QueryId,
    },
    data(){
        return { 
            menu: false,           
            show: false,
            userpassword: '',
            formData: {
                birth_certificate_pin: null,
                date_of_birth: null,
            },
            password: true,
            expand: true,
            formHasErrors: false,
        }
    },

    props: {
        source: String,
    },

    watch: {
        userpassword: {
            handler(val){
                this.formData.student_id = val;                
            }
        },              
    },

    computed: {
        ...mapGetters({
            getOverlay: 'auth/getOverlay',
            schoolName: 'form/getSchoolName',
            getloginErrors: 'auth/getLoginErrors',
            authenticated: 'auth/getAuthenticated',
            passwordHint: 'auth/getPasswordHint',
        }),
        
    },

    methods: {
        ...mapActions({
            signIn: 'auth/signIn',
        }),

        ...mapMutations({
            openDialog: 'auth/setDialog',
            setOverlay: 'auth/setOverlay',
        }),

        keyPressPassword (e) {            
            if(e.keyCode === 13){
                this.submit();
            }
        },

        keyPressId (e) {
            if(e.keyCode === 13){
                this.$refs.password.focus();
            }
        },       

        async submit () {
            this.formHasErrors = false;

            Object.keys(this.formData).forEach(f => {
                if(!this.formData[f]) this.formHasErrors = true
                this.$refs[f].validate(true)
            })

            if(this.formHasErrors){
                return;
            }    

            console.log('Authenticating..');
            await this.signIn(this.formData);
            
            if(this.authenticated){ 
                console.log('Authenticated');               
                this.$router.replace('/registration/student-data');
            }
            else{
                this.loginErrors = this.getLoginErrors 
            }
            this.setOverlay(false);                     
        },

    }
}
</script>

<style scoped>
    .container-background{
        /* background-image: url('../../assets/background.jpg'); */
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        filter: brightness(40%);
        position: absolute;
    }    
</style>

